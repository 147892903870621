<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <!-- id搜索 -->
        <el-input
          placeholder="id"
          clearable
          v-model="params.id"
          style="width: 60px"
        />
        <!-- 已投书籍名称 -->
        <el-select
          v-model="params.bookName"
          filterable
          remote
          clearable
          style="width: 120px"
          reserve-keyword
          @change="handleBookNameChange"
          placeholder="已投放书籍"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.bookName"
          >
          </el-option>
        </el-select>
        <!-- 未投放书籍 -->
        <el-input
          placeholder="未投放书籍"
          clearable
          @clear="booksName = null"
          v-model="booksName"
          style="width: 120px"
        />
        <!--  -->
        <!-- 公司是否投放过 -->
        <el-select
          v-model="params.putType"
          clearable
          placeholder="是否投放"
          style="width: 120px"
        >
          <el-option label="投放书籍" value="投放书籍"></el-option>
          <el-option label="未投放书籍" value="未投放书籍"></el-option>
        </el-select>
        <!-- 平均付费次数 -->
        <el-popover placement="bottom" width="300" trigger="click">
          <el-form label-width="60px">
            <el-form-item label="最小值">
              <el-input
                placeholder="最小付费次数"
                type="number"
                v-model="params.avgMin"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="最大值">
              <el-input
                placeholder="最大付费次数"
                type="number"
                v-model="params.avgMax"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-form>
          <el-input
            slot="reference"
            placeholder="付费次数区间"
            clearable
            @clear="handleClearAvg"
            :value="showTextAvg"
            style="width: 160px"
          />
        </el-popover>
        <!-- 付费人数 -->
        <el-popover placement="bottom" width="300" trigger="click">
          <el-form label-width="60px">
            <el-form-item label="最小值">
              <el-input
                placeholder="最小付费人数"
                type="number"
                v-model="params.payMin"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="最大值">
              <el-input
                placeholder="最大付费人数"
                type="number"
                v-model="params.payMax"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-form>
          <el-input
            slot="reference"
            placeholder="付费人数区间"
            clearable
            @clear="handleClearPay"
            :value="showTextPay"
            style="width: 150px"
          />
        </el-popover>
        <!-- 增幅区间 -->
        <el-popover placement="bottom" width="300" trigger="click">
          <el-form label-width="60px">
            <el-form-item label="最小值">
              <el-input
                placeholder="最小增幅"
                type="number"
                v-model="params.increaseMin"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="最大值">
              <el-input
                placeholder="最大增幅"
                type="number"
                v-model="params.increaseMax"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-form>
          <el-input
            slot="reference"
            placeholder="增幅区间"
            clearable
            @clear="handleClearIncrease"
            :value="showTextIncrease"
            style="width: 140px"
          />
        </el-popover>
        <el-select
          v-model="searchSuggstion"
          placeholder="建议"
          style="width: 80px"
        >
          <el-option label="建议关注" value="建议关注"></el-option>
          <el-option label="其它" value="其它"></el-option>
        </el-select>
        <!-- 用户成本区间 -->
        <el-popover placement="bottom" width="300" trigger="click">
          <el-form label-width="60px">
            <el-form-item label="最小值">
              <el-input
                placeholder="每用户最小成本"
                type="number"
                v-model="params.costMin"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="最大值">
              <el-input
                placeholder="每用户最大成本"
                type="number"
                v-model="params.costMax"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-form>
          <el-input
            slot="reference"
            placeholder="每用户成本区间"
            clearable
            @clear="handleClearCost"
            :value="showTextCost"
            style="width: 160px"
          />
        </el-popover>
        <!-- 最早投放日期 -->
        <el-date-picker
          v-model="params.earliestPutDate"
          style="width: 130px"
          type="date"
          align="right"
          :clearable="true"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="最早投放日期"
        >
        </el-date-picker>
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
        <el-popover
          placement="top"
          width="300"
          v-model="explainVisible"
          trigger="click"
        >
          <p>1.该分析模型反应书籍人均价值；</p>
          <p>2.纵向可以比较书籍与书籍之间人均价值大小；</p>
          <p>3.横向LTV可以通过增幅大小评价书籍潜力价值；</p>
          <p>4.此模型可以作为测书阶段完毕后评价书籍是否值得投放因素之一。</p>
          <p>
            5.列表中含有公司未投，但是客户通过公众号自主充值的书籍，根据这本书籍的数据表象，可以作为选书投放的参考。
          </p>
          <el-link slot="reference" type="warning" style="margin-left: 20px"
            >数据解读</el-link
          >
        </el-popover>
      </div>
    </div>
    <!-- 表格数据 -->
    <el-table
      class="book-value"
      :data="dataList"
      style="width: 100%"
      :border="true"
      height="705px"
      :header-cell-class-name="handleHeaderCell"
      @sort-change="handleSortChange"
    >
      <el-table-column
        prop="id"
        align="right"
        header-align="center"
        label="id"
        min-width="60"
      >
      </el-table-column>
      <el-table-column
        prop="bookName"
        align="left"
        header-align="center"
        :sortable="sortableType"
        label="书籍"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="putType"
        align="left"
        header-align="center"
        :sortable="sortableType"
        label="是否投放过"
        min-width="115"
      >
      </el-table-column>
      <el-table-column label="书籍人均价值" align="center">
        <el-table-column
          prop="ltvD1"
          label="LTV_D1"
          align="right"
          :sortable="sortableType"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="ltvD2"
          label="LTV_D2"
          align="right"
          :sortable="sortableType"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="ltvD3"
          label="LTV_D3"
          align="right"
          :sortable="sortableType"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="ltvD7"
          label="LTV_D7"
          align="right"
          :sortable="sortableType"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="ltvD15"
          label="LTV_D15"
          align="right"
          min-width="110"
          :sortable="sortableType"
        >
        </el-table-column>
        <el-table-column
          prop="ltvD30"
          label="LTV_D30"
          align="right"
          min-width="110"
          :sortable="sortableType"
        >
        </el-table-column>
        <el-table-column
          prop="ltvD60"
          label="LTV_D60"
          align="right"
          min-width="110"
          :sortable="sortableType"
        >
        </el-table-column>
        <el-table-column
          prop="ltvD90"
          label="LTV_D90"
          align="right"
          min-width="110"
          :sortable="sortableType"
        >
        </el-table-column>
      </el-table-column>
      <!-- 区间 -->
      <el-table-column label="指标评价" align="center">
        <el-table-column
          prop="earliestPutDate"
          label="最早投放日期"
          align="right"
          min-width="130"
          :sortable="sortableType"
        >
        </el-table-column>
        <el-table-column
          prop="userCost"
          label="每用户成本"
          align="right"
          min-width="130"
          :sortable="sortableType"
        >
        </el-table-column>
        <el-table-column
          prop="d90AvgUserPayCnt"
          label="人均付费次数"
          align="right"
          min-width="130"
          :sortable="sortableType"
        >
        </el-table-column>
        <el-table-column
          prop="d90PayUserCnt"
          label="付费人数"
          align="right"
          min-width="106"
          :sortable="sortableType"
        >
        </el-table-column>
        <el-table-column
          prop="d90IncreasePctStr"
          label="增幅"
          align="right"
          min-width="80"
          :sortable="sortableType"
        >
        </el-table-column>
        <el-table-column label="建议" align="right">
          <template slot-scope="scope">
            <span
              :class="{ 'color-red': scope.row.suggestion === '建议关注' }"
              >{{ scope.row.suggestion }}</span
            >
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <!-- 普通分页 -->
    <el-pagination
      style="margin: 10px 0; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="totalPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { yesterdayOptions, labelList } from "@/assets/js/options";
import { formatThousandsDots } from "@/assets/js/utils";
import { getBookValueAnalysis } from "@/api/statics";
import { getBookList } from "@/api/account";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "month",
  props: {
    lastParams: Object,
  },
  computed: {
    showTextCost() {
      const { costMin, costMax } = this.params;
      if (costMin && costMax) {
        return `每用户成本:${costMin}~${costMax}`;
      }
      return "";
    },
    showTextAvg() {
      const { avgMin, avgMax } = this.params;
      if ((avgMin === 0 || avgMin > 0) && (avgMax === 0 || avgMax > 0)) {
        return `付费次数:${avgMin}~${avgMax}`;
      } else if (avgMin === 0 || avgMin > 0) {
        return `最小付费次数:${avgMin}`;
      } else if (avgMax === 0 || avgMax > 0) {
        return `最大付费次数:${avgMax}`;
      }
      return "";
    },
    showTextPay() {
      const { payMin, payMax } = this.params;
      if ((payMin === 0 || payMin > 0) && (payMax === 0 || payMax > 0)) {
        return `付费人数:${payMin}~${payMax}`;
      } else if (payMin === 0 || payMin > 0) {
        return `最小付费人数:${payMin}`;
      } else if (payMax === 0 || payMax > 0) {
        return `最大付费人数:${payMax}`;
      }
      return "";
    },
    showTextIncrease() {
      const { increaseMin, increaseMax } = this.params;
      if (
        (increaseMin === 0 || increaseMin > 0) &&
        (increaseMax === 0 || increaseMax > 0)
      ) {
        return `增幅区间:${increaseMin}~${increaseMax}`;
      } else if (increaseMin === 0 || increaseMin > 0) {
        return `最小增幅:${increaseMin}`;
      } else if (increaseMax === 0 || increaseMax > 0) {
        return `最大增幅:${increaseMax}`;
      }
      return "";
    },
    ...mapGetters(["officialList"]),
    // 排序处理
    sortableType() {
      return "custom";
    },
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
    booksName: function (newval, oldval) {
      if (newval) {
        this.params.bookName = null;
      }
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
        // return formatThousandsDots(value.toString());
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      explainVisible: false,
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showTemplate: null,
      nextParams: {},
      page: 1,
      totalPage: 0,
      pageSize: 30,
      booksName: null,
      searchSuggstion: null,
    };
  },
  methods: {
    // 头部样式回调
    handleHeaderCell({ rowIndex, columnIndex }) {
      if (rowIndex === 0 && columnIndex === 3) {
        return "color-blue";
      }
      if (rowIndex === 1 && columnIndex === 9) {
        return "color-red";
      }
    },
    // 书籍变化
    handleBookNameChange(item) {
      if (item) {
        this.booksName = null;
      }
    },
    // 情况平均付费次数
    handleClearAvg() {
      this.params.avgMin = null;
      this.params.avgMax = null;
    },
    //
    handleClearCost() {
      this.params.costMin = null;
      this.params.costMax = null;
    },
    handleClearPay() {
      this.params.payMin = null;
      this.params.payMax = null;
    },
    handleClearIncrease() {
      this.params.increaseMin = null;
      this.params.increaseMax = null;
    },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 排序处理
    handleSortChange({ column, prop, order }) {
      // if (column.sortable !== "custom") {
      //   return;
      // }
      this.page = 1;
      this.sortName = prop === "d90IncreasePctStr" ? "d90IncreasePct" : prop;
      if (order === "descending") {
        this.sortType = "desc";
      } else if (order === "ascending") {
        this.sortType = "asc";
      } else {
        this.sortType = null;
        this.sortName = null;
      }
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.page = 1;
      this.sortType = null;
      this.sortName = null;
      this.params.id = null;
      this.params.avgMin = null;
      this.params.avgMax = null;
      this.params.payMin = null;
      this.params.payMax = null;
      this.params.increaseMin = null;
      this.params.increaseMax = null;
      this.params.bookName = null;
      this.params.putType = null;
      this.booksName = null;
      this.searchSuggstion = null;
      this.params.costMin = null;
      this.params.costMax = null;
      this.params.earliestPutDate = null;
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 广告数据获取
    getData(flag) {
      this.loading = true;
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          page: this.page,
          pageSize: this.pageSize,
          id: this.params.id,
          bookName: this.params.bookName
            ? this.params.bookName
            : this.booksName,
          putType: this.params.putType,
          avgMin: this.params.avgMin,
          avgMax: this.params.avgMax,
          payMin: this.params.payMin,
          payMax: this.params.payMax,
          costMin: this.params.costMin,
          costMax: this.params.costMax,
          earliestPutDate: this.params.earliestPutDate,
          increaseMin: this.params.increaseMin,
          increaseMax: this.params.increaseMax,
          sortName: this.sortName,
          sortType: this.sortType,
          suggestion: this.searchSuggstion,
        };
      } else if (flag === "drawer") {
        tmpform = {
          ...this.lastParams,
          page: this.page,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          page: this.page,
          pageSize: this.pageSize,
          id: this.params.id,
          bookName: this.params.bookName
            ? this.params.bookName
            : this.booksName,
          putType: this.params.putType,
          avgMin: this.params.avgMin,
          avgMax: this.params.avgMax,
          payMin: this.params.payMin,
          payMax: this.params.payMax,
          costMin: this.params.costMin,
          costMax: this.params.costMax,
          earliestPutDate: this.params.earliestPutDate,
          increaseMin: this.params.increaseMin,
          increaseMax: this.params.increaseMax,
          sortName: this.sortName,
          sortType: this.sortType,
          suggestion: this.searchSuggstion,
        };
      }
      getBookValueAnalysis(tmpform)
        .then((res) => {
          res.list.forEach((item) => {
            item.d90AvgUserPayCnt = Number(item.d90AvgUserPayCnt).toFixed(1);
          });
          this.dataList = res.list;
          this.totalPage = res.total;
          this.pageSize = res.pageSize;
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // 处理字段
  },
  created() {
    this.getBook();
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
    }
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
// 表头
/deep/ .el-table--border th,
/deep/.el-table .has-gutter tr th {
  border-bottom: 1px solid rgb(235, 238, 245) !important;
  border-right: 1px solid rgb(235, 238, 245) !important;
}
.book-value /deep/ .thead th {
  padding: 5px 0 !important;
}
/deep/ .el-table--small th {
  padding: 0 !important;
}
/deep/ .book-blue {
  background-color: #9cbae1 !important;
}
/deep/ .book-yellow {
  background-color: #e1a77b !important;
}
/deep/ .second-head {
  font-size: 12px !important;
}
.el-table /deep/ tr td {
  font-size: 13px;
}
/deep/ .color-red {
  color: #fd5d5a;
}
/deep/ .color-blue {
  background-color: #ddebf7 !important;
}
</style>
